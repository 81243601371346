import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, noop } from 'lodash';
import { connect } from 'react-redux';
import { venomHistory } from 'client/utils/history/venom-history';
import { getAppraisalLink } from 'site-modules/shared/utils/appraisal-link-constructor';
import { checkVinInventory } from 'site-modules/shared/utils/appraisal/check-vin-inventory';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'client/site-modules/shared/constants/appraisal/appraisal';
import { AppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { AppraisalTabs } from 'site-modules/shared/components/appraisal/appraisal-tabs-container/appraisal-tabs';
import { usePrevious } from 'site-modules/shared/hooks/use-previous';
import { getQuery } from 'client/utils/location';
import { hasChicagoBearsParam, isChicagoBearsRootPage } from 'site-modules/shared/utils/appraiser/promotions-utils';

export function AppraisalTabsContextProvider({ WrappedComponent, ...props }) {
  const [vin, setVin] = useState('');
  const [isVinEdited, setIsVinEdited] = useState(false);
  const [wasVinPrePopulated, setWasVinPrePopulated] = useState(false);
  const [licensePlate, setLicensePlate] = useState('');
  const [stateCode, setStateCode] = useState(props.initialStateCode);
  const [mmy, setMmy] = useState({});
  const [addressInfo, setAddressInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    apartment: '',
    // IMPORTANT: city should be undefined in order to detect user input.
    city: undefined,
  });
  const [styleIds, setStyleIds] = useState([]);
  const [isReadyForRedirect, setIsReadyForRedirect] = useState(false);
  const [squishStyles, setSquishStyles] = useState(null);
  const [vinStyles, setVinStyles] = useState(null);
  const [isAppraiserPageOpened, setIsAppraiserPageOpened] = useState(false);

  const {
    isAppExtensionPage,
    isVdpEmbedded,
    isLookUpStyleView,
    location,
    ctaBtnColor,
    onFindVinModalOpen,
    onFindVinModalClose,
    onMultipleVinsModalOpen,
    onMultipleVinsModalClose,
    isProjectEveStyle,
    pageName,
    emo2507Recipe,
  } = props;

  const prevIsReadyForRedirect = usePrevious(isReadyForRedirect);
  const isChicagoBearsPage = useMemo(() => isChicagoBearsRootPage(pageName), [pageName]);
  const hasChicagoBearsPromoParam = useMemo(() => hasChicagoBearsParam(location), [location]);

  const setDefaultButtonStateForCachedPage = useCallback(({ persisted }) => {
    if (persisted) {
      setIsReadyForRedirect(false);
      setVin('');
      setLicensePlate('');
      setMmy({});
    }
  }, []);

  useEffect(() => {
    window.addEventListener('pageshow', setDefaultButtonStateForCachedPage);

    return () => {
      setIsReadyForRedirect(false);
      window.removeEventListener('pageshow', setDefaultButtonStateForCachedPage);
    };
  }, [setDefaultButtonStateForCachedPage]);

  useEffect(() => {
    if (isReadyForRedirect && isReadyForRedirect !== prevIsReadyForRedirect) {
      const { make, model, year } = mmy;
      const { modsId, emo } = getQuery(location);
      let styleIdsParam;

      /* For EMO-2507
       * If in ctrl, vinStyles is empty and follows regular logic
       * If in chal1, we want only styleIds from vin decode
       */
      if (!isEmpty(vinStyles)) {
        styleIdsParam = vinStyles?.styles?.map(style => style?.id) ?? [];
      } else {
        styleIdsParam = styleIds;
      }

      const appraisalPathLink = getAppraisalLink({
        make,
        model,
        year,
        queryParams: {
          vin,
          styleIds: styleIdsParam?.join(','),
          modsId,
          emo,
          ...(isChicagoBearsPage ? { promotions: 'chicagobears', emo: 'false' } : {}),
        },
        isAppExtensionPage,
      });

      if (isVdpEmbedded) {
        window.open(appraisalPathLink, '_blank');
        setIsReadyForRedirect(false);
        setIsAppraiserPageOpened(true);
      } else if (!isLookUpStyleView) {
        venomHistory.push(appraisalPathLink);
      }

      if (vin) {
        checkVinInventory(vin, APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID, noop);
      }
    }
  }, [
    emo2507Recipe,
    isAppExtensionPage,
    isChicagoBearsPage,
    isLookUpStyleView,
    isReadyForRedirect,
    isVdpEmbedded,
    location,
    mmy,
    pageName,
    prevIsReadyForRedirect,
    styleIds,
    vin,
    vinStyles,
  ]);

  const queryVin = get(location, 'query.vin', '').toUpperCase();
  const contextProps = useMemo(
    () => ({
      licensePlate,
      setLicensePlate,
      mmy,
      setMmy,
      vin,
      setVin,
      isVinEdited,
      setIsVinEdited,
      queryVin,
      stateCode,
      setStateCode,
      styleIds,
      setStyleIds,
      squishStyles,
      setSquishStyles,
      vinStyles,
      setVinStyles,
      addressInfo,
      setAddressInfo,
      isReadyForRedirect,
      setIsReadyForRedirect,
      isAppraiserPageOpened,
      ctaBtnColor,
      onFindVinModalOpen,
      onFindVinModalClose,
      onMultipleVinsModalOpen,
      onMultipleVinsModalClose,
      isProjectEveStyle,
      hasChicagoBearsPromoParam,
      wasVinPrePopulated,
      setWasVinPrePopulated,
      emo2507Recipe,
    }),
    [
      ctaBtnColor,
      hasChicagoBearsPromoParam,
      isAppraiserPageOpened,
      isProjectEveStyle,
      isReadyForRedirect,
      licensePlate,
      mmy,
      vin,
      isVinEdited,
      onFindVinModalClose,
      onFindVinModalOpen,
      onMultipleVinsModalClose,
      onMultipleVinsModalOpen,
      queryVin,
      squishStyles,
      vinStyles,
      stateCode,
      styleIds,
      addressInfo,
      wasVinPrePopulated,
      emo2507Recipe,
    ]
  );

  return (
    <AppraisalTabsContext.Provider value={contextProps}>
      <WrappedComponent
        {...props}
        vin={vin}
        setMmy={setMmy}
        setStyleIds={setStyleIds}
        setIsReadyForRedirect={setIsReadyForRedirect}
        setVin={setVin}
        emo2507Recipe={emo2507Recipe}
      />
    </AppraisalTabsContext.Provider>
  );
}

AppraisalTabsContextProvider.propTypes = {
  WrappedComponent: PropTypes.elementType.isRequired,
  ctaBtnColor: PropTypes.string,
  onFindVinModalOpen: PropTypes.func,
  onFindVinModalClose: PropTypes.func,
  onMultipleVinsModalOpen: PropTypes.func,
  onMultipleVinsModalClose: PropTypes.func,
};

AppraisalTabsContextProvider.defaultProps = {
  ctaBtnColor: 'success',
  onFindVinModalOpen: noop,
  onFindVinModalClose: noop,
  onMultipleVinsModalOpen: noop,
  onMultipleVinsModalClose: noop,
};

// A little hack to test AppraisalTabsContextProvider's logic that is passed to the context
export function AppraisalTabsContainerUI(props) {
  return <AppraisalTabsContextProvider {...props} WrappedComponent={AppraisalTabs} />;
}

export const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
  location: get(state, 'pageContext.location'),
  referer: get(state, 'pageContext.page.referer'),
  profileOfferVin: get(state, 'profile.data.idm.appraisalOffersHistory[0].vin'),
  isAppExtensionPage: get(state, 'pageContext.isAppExtensionPage'),
  pageName: get(state, 'pageContext.page.name'),
});

export const AppraisalTabsContainer = connect(mapStateToProps)(AppraisalTabsContainerUI);
