import React from 'react';
import PropTypes from 'prop-types';

export function ErrorFieldCopy({ id, className, copy, tag }) {
  const Tag = tag;
  return (
    <Tag id={id} role="alert" className={className}>
      {copy}
    </Tag>
  );
}

ErrorFieldCopy.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  copy: PropTypes.string,
  tag: PropTypes.string,
};

ErrorFieldCopy.defaultProps = {
  className: '',
  copy: '',
  tag: 'div',
};
