import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { RadioListItem } from 'site-modules/shared/components/radio-list/radio-list-item';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { INSTANT_OFFER } from 'site-modules/shared/constants/appraisal/appraisal';
import { FOUND_VIN_SUBHEADER } from './constants';

export function FindVinByAddressSelectVehicleView({
  vehicles,
  selectedVin,
  setSelectedVin,
  isSubmitting,
  continueWithSelectedVehicle,
  ctaBtnColor,
  isAddressTab,
}) {
  const isMultipleVehicles = vehicles.length > 1;
  const subheader = isMultipleVehicles ? FOUND_VIN_SUBHEADER.MULTIPLE_VINS : FOUND_VIN_SUBHEADER.SINGLE_VIN;

  return (
    <Fragment>
      <header className={classNames('mb-1 pos-r text-gray-darker', isAddressTab ? 'text-center' : 'mt-1_5')}>
        <h4 className="size-24">Great news!</h4>
        <div>{subheader}</div>
      </header>
      {(isMultipleVehicles || isAddressTab) &&
        vehicles.map((vehicle, ind) => (
          <RadioListItem
            key={`${vehicle.vin}-radio`}
            name={`${vehicle.vin}-radio`}
            onChange={setSelectedVin}
            checked={selectedVin === vehicle.vin}
            formGroupClassName={classNames('rounded find-vin-vehicle', {
              'active bg-white': selectedVin === vehicle.vin,
              'bg-gray-extra-light': selectedVin !== vehicle.vin && !isAddressTab,
              'bg-cool-gray-80': selectedVin !== vehicle.vin && isAddressTab,
              'address-tab-radio-item px-1 py-0_75': isAddressTab,
              'p-0_75': !isAddressTab,
              'mb-1': ind !== vehicles.length - 1,
            })}
            listItem={{
              title: vehicle.vin,
              hideValue: true,
              text: (
                <div>
                  <div
                    className={classNames(
                      'text-capitalize',
                      isAddressTab ? 'text-cool-gray-40 fw-bold mb-0_25' : 'text-gray-darker fw-medium'
                    )}
                  >
                    {vehicle.modelYear} {vehicle.make} {vehicle.modelDesc}
                  </div>
                  <div className={classNames(isAddressTab ? 'text-cool-gray-40' : 'text-gray-darker')}>
                    <span className="fw-bold me-0_25">VIN:</span>
                    {vehicle.vin}
                  </div>
                </div>
              ),
            }}
          />
        ))}
      {!isMultipleVehicles && !isAddressTab && (
        <div className="bg-gray-extra-light p-0_75 rounded find-vin-vehicle d-flex align-items-baseline">
          <i className="icon-checkmark text-success me-0_5" aria-hidden />
          <div>
            <div className="text-gray-darker fw-medium text-capitalize">
              {vehicles[0].modelYear} {vehicles[0].make} {vehicles[0].modelDesc}
            </div>
            <div className="text-gray-darker">
              <span className="fw-bold me-0_25">VIN:</span>
              {vehicles[0].vin}
            </div>
          </div>
        </div>
      )}
      <Button
        color={ctaBtnColor}
        className={classNames('mt-1_5 py-0_5 text-transform-none', { cta: isAddressTab })}
        data-index={INSTANT_OFFER}
        onClick={continueWithSelectedVehicle}
        disabled={!selectedVin || isSubmitting}
        size={isAddressTab ? 'lg' : undefined}
      >
        {isSubmitting && (
          <span className="pe-0_5">
            <Spinner size={14} thickness={1} color="white" />
          </span>
        )}
        <span className={classNames('size-16', isAddressTab ? 'fw-medium' : 'fw-bold')}>
          Continue with this vehicle
        </span>
      </Button>
    </Fragment>
  );
}

FindVinByAddressSelectVehicleView.propTypes = {
  vehicles: PropTypes.arrayOf(PropTypes.shape({})),
  selectedVin: PropTypes.string,
  setSelectedVin: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  continueWithSelectedVehicle: PropTypes.func.isRequired,
  ctaBtnColor: PropTypes.string,
  isAddressTab: PropTypes.bool,
};

FindVinByAddressSelectVehicleView.defaultProps = {
  vehicles: [],
  selectedVin: '',
  isSubmitting: false,
  ctaBtnColor: '',
  isAddressTab: false,
};
