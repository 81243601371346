import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { YmmsDropdowns } from 'site-modules/shared/components/ymms-dropdowns/ymms-dropdowns';
import { MmyDropdowns } from 'client/site-modules/shared/components/mmy-dropdowns/mmy-dropdowns';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { buildNoSubmodelVehiclePath, VehicleModel } from 'client/data/models/vehicle';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { isEmpty, noop } from 'lodash';
import { PUB_STATES } from 'client/constants/pub-states';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { AppraisalTabsSubmitButton } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-submit-button';
import { isEqualById } from 'site-modules/shared/utils/appraiser/compare-utils';
import { delayHeavyTask } from 'client/site-modules/shared/utils/inp-utils';

import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'client/site-modules/shared/constants/appraisal/appraisal';
import { AppraiseTabContentFormWrapper } from './appraise-tab-content-form-wrapper';

const ERROR_MESSAGES = {
  make: 'Please select a make',
  model: 'Please select a model',
  year: 'Please select a year',
};

const DROPDOWNS_PLACEHOLDERS = {
  year: 'Select year',
  make: 'Select make',
  model: 'Select model',
};

const DROPDOWNS_LABELS = {
  year: 'Year',
  make: 'Make',
  model: 'Model',
};

const DROPDOWNS_PUB_STATES = [PUB_STATES.USED, PUB_STATES.NEW_USED];

const TRACKING_VALUE = 'ymm';

export class AppraiseByMmyTabContentUI extends PureComponent {
  static propTypes = {
    isFullWidth: PropTypes.bool,
    ctaText: PropTypes.string,
    btnClassName: PropTypes.string,
    btnTrackingId: PropTypes.string,
    query: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      year: PropTypes.string,
    }),
    vehicle: PropTypes.shape({
      id: PropTypes.number,
    }),
    skipValidation: PropTypes.bool,
    isVdpEmbedded: PropTypes.bool,
    isYearDropdownFirst: PropTypes.bool,
    isAppExtensionPage: PropTypes.bool,
    isBackgroundStyleTabEnabled: PropTypes.bool,
    isMobile: PropTypes.bool,
    initialVehicle: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    handlePrepopulatedVehicle: PropTypes.func,
    dropdownPlaceholders: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      year: PropTypes.string,
    }),
    dropdownLabels: PropTypes.shape({
      make: PropTypes.string,
      model: PropTypes.string,
      year: PropTypes.string,
    }),
    location: PropTypes.shape({}),
    ctaBtnColor: PropTypes.string,
    isProjectEveStyle: PropTypes.bool,
    mmy: PropTypes.shape({}),
    setMmy: PropTypes.func.isRequired,
    isReadyForRedirect: PropTypes.bool,
    setIsReadyForRedirect: PropTypes.func.isRequired,
    setVin: PropTypes.func.isRequired,
    setStyleIds: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isFullWidth: false,
    ctaText: 'Appraise My Car',
    btnClassName: null,
    btnTrackingId: null,
    query: {},
    vehicle: {},
    skipValidation: false,
    isVdpEmbedded: false,
    isYearDropdownFirst: true,
    isAppExtensionPage: false,
    isBackgroundStyleTabEnabled: false,
    isMobile: false,
    initialVehicle: null,
    handlePrepopulatedVehicle: noop,
    dropdownPlaceholders: DROPDOWNS_PLACEHOLDERS,
    dropdownLabels: DROPDOWNS_LABELS,
    location: {},
    ctaBtnColor: 'success',
    isProjectEveStyle: false,
    mmy: {},
    isReadyForRedirect: false,
  };

  state = {
    validated: false,
    prepopulatedVehicle: undefined,
  };

  componentDidMount() {
    const { initialVehicle } = this.props;

    if (!isEmpty(initialVehicle)) {
      this.onChange(initialVehicle);
      this.setPrepopulatedVehicle(initialVehicle);
    }
  }

  componentDidUpdate(prevProps) {
    const { vehicle, query } = this.props;
    if (!isEmpty(vehicle) && !isEqualById(prevProps.vehicle, vehicle)) {
      this.onChange(query);
      this.setPrepopulatedVehicle(query);
    }
  }

  onChange = ({ make, model, year }) => {
    const { setMmy } = this.props;
    setMmy({ make, model, year });
    this.setState({ validated: false });
  };

  setPrepopulatedVehicle = ({ make, model, year }) => {
    const prepopulatedVehicle = {
      year,
      makeSlug: make,
      modelSlug: model,
    };
    this.setState({
      prepopulatedVehicle,
    });
    this.props.handlePrepopulatedVehicle(prepopulatedVehicle);
  };

  getAppraisalButton = () => {
    const {
      btnTrackingId,
      ctaText,
      btnClassName,
      isBackgroundStyleTabEnabled,
      isProjectEveStyle,
      isReadyForRedirect,
    } = this.props;

    return (
      <AppraisalTabsSubmitButton
        onSubmit={this.validate}
        buttonClassName={classnames('py-0_75 btn-responsive-md-down', btnClassName, {
          'mt-lg-1_75': isBackgroundStyleTabEnabled || isProjectEveStyle,
        })}
        data-test="appraise-btn"
        data-tracking-id={btnTrackingId}
        isDisabled={isReadyForRedirect}
        isSubmitting={isReadyForRedirect}
        ctaText={ctaText}
      />
    );
  };

  isMmyPresent = () => {
    const { mmy } = this.props;
    return mmy?.make && mmy?.model && mmy?.year;
  };

  validate = e => {
    e.preventDefault();

    this.setState({
      validated: true,
    });

    const isReadyForRedirect = this.isMmyPresent();

    if (isReadyForRedirect) {
      this.trackAppraisalSubmit();
    }
  };

  trackAppraisalSubmit = () => {
    const { setIsReadyForRedirect, setVin, setStyleIds } = this.props;

    delayHeavyTask(() => {
      setVin('');
      setStyleIds([]);
      setIsReadyForRedirect(!!this.isMmyPresent());
    });

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        subaction_name: TrackingConstant.SUBMIT_VIN,
        creative_id: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
        value: TRACKING_VALUE,
      },
    });
  };

  render() {
    const { validated, prepopulatedVehicle } = this.state;
    const {
      isFullWidth,
      isYearDropdownFirst,
      isBackgroundStyleTabEnabled,
      dropdownPlaceholders,
      dropdownLabels,
      skipValidation,
      isProjectEveStyle,
      mmy,
      isReadyForRedirect,
    } = this.props;

    const { make, model, year } = mmy;

    const displayError = {
      make: validated && !make,
      model: validated && !model,
      year: validated && !year,
    };

    const dropdownColSize = {
      year: {
        xs: 12,
        lg: 4,
      },
      make: {
        xs: 12,
        lg: 4,
      },
      model: {
        xs: 12,
        lg: 4,
      },
    };

    const commonDropdownsProps = {
      skipValidation,
      dropdownPlaceholders,
      dropdownLabels,
      labelClassName: 'size-12',
      includeNextYear: false,
      formGroupClassName: 'mb-1_5',
      onChange: this.onChange,
      errorMessages: ERROR_MESSAGES,
      displayError,
      pubStates: DROPDOWNS_PUB_STATES,
      prepopulatedVehicle,
      isFormControlErrorHighlighted: true,
      disabled: isReadyForRedirect,
      ...(isProjectEveStyle || isBackgroundStyleTabEnabled
        ? {
            dropdownColSize,
            className: 'd-lg-flex justify-space-lg-between',
            rowClassName: 'flex-grow-1 pe-lg-1',
          }
        : {}),
    };

    return (
      <div className="appraise-by-mmy-tab">
        <AppraiseTabContentFormWrapper isFullWidth={isFullWidth}>
          {isYearDropdownFirst ? (
            <YmmsDropdowns
              useUniqId
              useStyledSelects
              hasModelsDropdown
              hasSubmodelsDropdown={false}
              hasStylesDropdown={false}
              {...commonDropdownsProps}
            >
              {this.getAppraisalButton()}
            </YmmsDropdowns>
          ) : (
            <MmyDropdowns {...commonDropdownsProps}>{this.getAppraisalButton()}</MmyDropdowns>
          )}
        </AppraiseTabContentFormWrapper>
      </div>
    );
  }
}

export const mapStateToProps = state => ({
  location: state.pageContext?.location,
  isAppExtensionPage: state.pageContext?.isAppExtensionPage,
});

export const stateToPropsConfig = {
  vehicle: bindToPath(({ query = {} }) => {
    const { make, model, year } = query;
    return make && model && year ? buildNoSubmodelVehiclePath({ make, model, year }) : '';
  }, VehicleModel),
};

export function AppraiseByMmyTabContentContainer(props) {
  const { ctaBtnColor, isProjectEveStyle } = useAppraisalTabsContext();

  return <AppraiseByMmyTabContentUI {...props} ctaBtnColor={ctaBtnColor} isProjectEveStyle={isProjectEveStyle} />;
}

export const AppraiseByMmyTabContent = connect(mapStateToProps)(
  connectToModel(AppraiseByMmyTabContentContainer, stateToPropsConfig)
);
