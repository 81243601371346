import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Button, Modal } from 'reactstrap';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { FindVinModalContentAsync } from './find-vin-modal-content-async';

import './find-vin-modal.scss';

export class FindVinModal extends Component {
  static propTypes = {
    creativeId: PropTypes.string.isRequired,
    className: PropTypes.string,
    openCtaAsButtonClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    isInfoIcon: PropTypes.bool,
    isBackgroundStyleTabEnabled: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    className: 'p-0 border-0 text-primary-darker text-transform-none size-16 find-vin-modal-open-btn',
    openCtaAsButtonClassName: 'bg-primary-opacity-2',
    iconClassName: 'text-primary-darker',
    isInfoIcon: false,
    isBackgroundStyleTabEnabled: false,
    onOpen: noop,
    onClose: noop,
  };

  state = {
    isOpen: false,
  };

  trackModalToggle = () => {
    const { creativeId } = this.props;
    const { isOpen } = this.state;

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        subaction_name: isOpen ? TrackingConstant.FIND_VIN : TrackingConstant.CLOSE_POPUP,
        creative_id: creativeId,
        value: isOpen ? 'open' : 'close',
      },
    });
  };

  handleModalOpenUpdate = () => {
    const { onOpen, onClose } = this.props;
    const { isOpen } = this.state;

    if (isOpen) {
      onOpen();
    } else {
      onClose();
    }

    this.trackModalToggle();
  };

  toggleModal = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }), this.handleModalOpenUpdate);

  renderButton = () => {
    const { isBackgroundStyleTabEnabled, className, openCtaAsButtonClassName, isInfoIcon, iconClassName } = this.props;

    return isBackgroundStyleTabEnabled ? (
      <Button
        color="custom"
        className={classnames('px-1 py-0_25 rounded-pill size-14 mt-0_5', openCtaAsButtonClassName)}
        onClick={this.toggleModal}
      >
        <i className={classnames('icon icon-search', iconClassName)} aria-hidden />
        <span className="fw-normal text-gray-darker ms-0_25">Find my VIN</span>
      </Button>
    ) : (
      <Button color="link" className={className} onClick={this.toggleModal}>
        <span className="fw-normal">
          {!isInfoIcon ? 'Where can I find my VIN?' : <i className="text-gray icon icon-info size-12" aria-hidden />}
        </span>
      </Button>
    );
  };

  render() {
    const { isOpen } = this.state;

    return (
      <Fragment>
        {this.renderButton()}
        <Modal
          modalClassName="p-1 p-md-2 p-lg-3"
          className="find-vin-modal my-0 mx-auto"
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          labelledBy="find-vin-modal-title"
          zIndex={1056} // make sure vin modal renders on top of drawers ($zindex-modal-drawer + 1)
        >
          {isOpen && <FindVinModalContentAsync toggleModal={this.toggleModal} />}
        </Modal>
      </Fragment>
    );
  }
}
