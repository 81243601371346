import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormGroup } from 'reactstrap';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { StatesModel, StateEntities } from 'client/data/models/states';
import { randomInt } from 'client/utils/seed-randomizers';
import { StyledSelect } from 'site-modules/shared/components/styled-select/styled-select';
import { FieldError } from 'site-modules/shared/components/field-error/field-error';

export function LicensePlateTabStateCodeInputUI({
  stateCodes,
  stateCodeInputValue,
  onStateCodeChange,
  errorMap,
  isLocationStyleStep,
  isVinByAddressOverlay,
  isAppraisalValuePage,
  isEscapeHatch,
  isFontSize12,
  isFontSize16,
  isAddressTab,
}) {
  const uniqInputId = useRef(randomInt());

  const stateCodeInputName = 'state-code';
  const stateCodeInputId = `${stateCodeInputName}${uniqInputId.current}`;
  const hasStateCodeError = errorMap.has('stateCode');

  return (
    <FormGroup
      className={classnames({
        'has-danger': hasStateCodeError,
        'ps-md-0_75 ps-lg-1': isLocationStyleStep,
        'text-start': isVinByAddressOverlay,
      })}
    >
      <StyledSelect
        id={stateCodeInputId}
        name={stateCodeInputName}
        labelText="State"
        labelClassName={classnames({
          'fw-bold': isAppraisalValuePage && !isEscapeHatch && !isLocationStyleStep && !isAddressTab,
          'size-12': isFontSize12,
          'size-14 text-gray-darker': isEscapeHatch,
          'size-16 text-start fw-bold': isVinByAddressOverlay && !isAddressTab,
        })}
        className={classnames({
          'text-gray-dark': isEscapeHatch,
          'size-16': isFontSize16,
          'size-14': !isFontSize16,
        })}
        toggle="Select State"
        labelKey="stateCode"
        valueKey="stateCode"
        options={stateCodes}
        value={stateCodeInputValue}
        onChange={onStateCodeChange}
        ariaDescribedBy={`${stateCodeInputId}-error`}
        ariaInvalid={hasStateCodeError}
        aria-required
        required
      />
      <FieldError id={`${stateCodeInputId}-error`} error={errorMap.get('stateCode')} classes="mt-0_5" isLiveRegion />
    </FormGroup>
  );
}

LicensePlateTabStateCodeInputUI.propTypes = {
  stateCodes: StateEntities.States,
  stateCodeInputValue: PropTypes.string,
  onStateCodeChange: PropTypes.func.isRequired,
  errorMap: PropTypes.shape({}),
  isLocationStyleStep: PropTypes.bool,
  isVinByAddressOverlay: PropTypes.bool,
  isAppraisalValuePage: PropTypes.bool,
  isEscapeHatch: PropTypes.bool,
  isFontSize12: PropTypes.bool,
  isFontSize16: PropTypes.bool,
  isAddressTab: PropTypes.bool,
};

LicensePlateTabStateCodeInputUI.defaultProps = {
  stateCodes: [],
  stateCodeInputValue: '',
  errorMap: new Map(),
  isLocationStyleStep: false,
  isVinByAddressOverlay: false,
  isAppraisalValuePage: false,
  isEscapeHatch: false,
  isFontSize12: false,
  isFontSize16: false,
  isAddressTab: false,
};

export const stateToPropsConfig = {
  stateCodes: bindToPath('allStateCodes', StatesModel),
};

export const LicensePlateTabStateCodeInput = connectToModel(LicensePlateTabStateCodeInputUI, stateToPropsConfig);
