import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';

export function AppraisalTabsSubmitButton({
  onSubmit,
  isDisabled,
  isSubmitting,
  ctaText,
  containerClassName,
  buttonClassName,
  ...restProps
}) {
  const { ctaBtnColor } = useAppraisalTabsContext();

  return (
    <div className={classnames('appraisal-tabs-submit-btn-container', containerClassName)}>
      <Button
        color={ctaBtnColor}
        className={classnames(
          'appraisal-tabs-submit-btn text-transform-none cta-btn d-flex align-items-center justify-content-center',
          buttonClassName
        )}
        onClick={onSubmit}
        disabled={isDisabled}
        {...restProps}
      >
        {isSubmitting && (
          <span className="pe-0_5" data-testid="appraise-btn-spinner">
            <Spinner size={14} thickness={1} color="white" />
          </span>
        )}
        <span className="size-16">{ctaText}</span>
      </Button>
    </div>
  );
}

AppraisalTabsSubmitButton.propTypes = {
  ctaText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};

AppraisalTabsSubmitButton.defaultProps = {
  ctaText: 'Appraise My Car',
  isSubmitting: false,
  isDisabled: false,
  containerClassName: '',
  buttonClassName: '',
};
